@import '../../../../../global/fonts.scss';

$gap: 16px;

#custom-entity-wrapper {
    margin-bottom: 16px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 50px 10px rgba(37, 40, 49, 0.06);
    display: flex;
    flex-direction: column;
    padding: $gap;

    h1 {
        color:  #000;
        @include OpenSansFont(700, 24px, 16px);
        margin-bottom: $gap;
        padding-left: $gap / 4;
    }

    span {
        color: #000;
        @include OpenSansFont();
        margin-bottom: $gap + 8px;
        padding-left: $gap / 4;
    }

    .col, .col-md-6 {
        margin: 8px 0;
    }

    #custom-entity-translations-section{
        border-radius: 6px; 
        background: #FAFAFA;
        padding: $gap/2;
        margin: auto (-$gap/2);
    }

    #custom-entity-uploads-container {
        margin: 8px 0;

        .dropzone-custom-entity-class {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            border-radius: 6px;
            border: 1px dashed  #D9D9D9;
            background: #FFF; 
            padding: 24px 12px;
            margin-bottom: 16px;
    
            span {
                margin-bottom: unset;
            }
    
            .dropzone-icon-upload {
                width: 24px;
                height: 24px;
                background-image: url('../../../../../assets/icons/general/upload_file.svg');
                margin-bottom: 4px;
            }
    
            .dropzone-dnd-text {
                display: block;
                width: 100%;
                text-align: center;
                @include OpenSansFont();
    
                .upload-image-button {
                    height: 20px;
                    text-decoration: underline;
                    color: #1058E1;
                    cursor: pointer;
                }
            }
    
            .dropzone-dnd-subtext {
                display: block;
                width: 100%;
                text-align: center;
                color:  #8C8C8C;
                @include OpenSansFont(400, 18px, 11px);
            }
        }
    }
}