@import "../general/variables.scss";

.custom-input-wrapper {
    min-width: 150px;
    width: 100%;
    display: inline-block;
    font-family: Arial, sans-serif;
    position: relative;
  
  label {
    display: block;
    font-size: 14px;
    color: #000;
    margin-bottom: $elementsMargin;
  }

  .custom-input {
    text-indent: 12px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    line-height: $lineHeight;
    height: $lineHeight;
  }
}  