@import "../general/variables.scss";

.custom-select-input-wrapper {
    min-width: 150px;
    width: 100%;
    display: inline-block;
    font-family: Arial, sans-serif;
    position: relative;
  
  label {
    display: block;
    font-size: 14px;
    color: #000;
    margin-bottom: $elementsMargin;
  }
  
  .custom-select-value-holder {
    width: 100%;
    height: $lineHeight;
    line-height: $lineHeight;
    padding: 0 $elementsPadding;
    font-size: 14px;
    border: 1px solid #BCC4D6;
    border-radius: $borderRadius;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    position: relative;
    z-index: 1;
    cursor: pointer;
    
    .select-icon {
      position: absolute;
      right: $elementsPadding;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #000;
      z-index: 2;
    }
  }
  
  .custom-select-options {
    margin-top: $elementsMargin;
    width: 100%;
    font-size: 14px;
    border: 1px solid #BCC4D6;
    border-radius: $borderRadius;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    position: absolute;
    z-index: 2;

    .custom-select-option {
      cursor: pointer;
      transition: background-color 0.3s;
      padding: 0 16px;
      line-height: 36px;


      &:hover {
        background-color: #E7EFFF;
      }

      &:first-child {
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
      }

      &:last-child {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
      }
    
    }
  }
}  